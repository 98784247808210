<template>
  <div class="pagenotfound-wrapper">
    <h2  class="pagenotfound_header">We Can't find the page you requested</h2>
      <p>You might have followed an incorrect link or mistyped an URL <br>But you can access our site by using the links provided below</p>
      <router-link to="/home">Home</router-link>
  </div>
</template>

<script>
  export default {
    name: 'pageNotFound'
  }
</script>

<style lang="scss" scoped>
@import "../assets/css/styles.scss";

.pagenotfound-wrapper {
    width: 94%;
    position: relative;
    overflow: hidden;
    margin-left: 16px;
    margin-right: 71px;

  .pagenotfound_header {
      margin: $mds-space-2-x 0;
      @include mds-level-2-heading($bold: false);
  }
}
</style>